import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    isPasswordValid;
    isPasswordConfirmationValid;
    connect() {
        this.getSetPasswordFormElements();
    }

    getSetPasswordFormElements() {
        this.submitButton = document.getElementById("submit_button");
        this.password = document.getElementById("password");
        this.passwordError = document.getElementById("password_error");
        this.confirmPassword = document.getElementById("password_confirmation");
        this.passwordConfirmationError = document.getElementById("password_confirmation_error");
    }

    validatePasswordInput() {
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;
        if (passwordPattern.test(this.password.value)) {
            this.isPasswordValid = true;
            this.setElementToValid(this.password);
            this.setElementInnerText(this.passwordError, "");
        } else {
            this.setElementInnerText(this.passwordError, "Your password needs to be at least 6 characters and include a letter and a number");
            this.isPasswordValid = false;
            this.setElementToInvalid(this.password);
        }
        this.validateForm();
    }

    validatePasswordConfirmationInput() {
        if (this.password.value === this.confirmPassword.value) {
            this.isPasswordConfirmationValid = true;
            this.setElementToValid(this.confirmPassword);
            this.setElementInnerText(this.confirmPassword, "");
        } else {
            this.setElementInnerText(this.passwordConfirmationError, "Your passwords do not match");
            this.isPasswordConfirmationValid = false;
            this.setElementToInvalid(this.confirmPassword);
        }
        this.validateForm();
    }

    validateForm() {
        this.submitButton.disabled = !(this.isPasswordValid === true && this.isPasswordConfirmationValid === true);
    }

    setElementInnerText(element, text) {
        element.innerText = text;
    }

    setElementToValid(element) {
        element.classList.add("is-valid");
        element.classList.remove("is-invalid")
    }

    setElementToInvalid(element) {
        element.classList.add("is-invalid");
        element.classList.remove("is-valid");
    }
}
