import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="radio-toggle"
export default class extends Controller {
    static targets = ["enableBopis", "syncDirectionOptions", "icon", "input"]

    connect() {
        this.iconTarget.className = this.getIconClass(this.inputTarget.value)
    }

    setSyncOrders(event) {
        if (this.hasEnableBopisTarget) {
            if (!event.target.checked) {
                this.enableBopisTarget.checked = false
            }
            this.enableBopisTarget.disabled = !event.target.checked
        }

        if (this.hasSyncDirectionOptionsTarget) {
            let options = this.syncDirectionOptionsTarget.querySelectorAll('input[type="radio"]')

            if (event.target.checked) {
                options[0].checked = true
            } else {
                options.forEach((el) => { el.checked = false })
            }

            options.forEach((el) => { el.disabled = !event.target.checked })
        }
    }

    getIconType(syncType) {
        switch (syncType) {
            case "IGNORE":
                return "ban"
            case "READ":
                return "arrow-right"
            case "WRITE":
                return "arrow-left"
            case "READ_WRITE":
                return "arrows-h"
            default:
                return "question"
        }
    }

    getIconClass(syncType) {
        return `fa fa-fw fa-${this.getIconType(syncType)}`
    }

    changeIcon(event) {
        this.iconTarget.className = this.getIconClass(event.target.value)
    }
}
