import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"
// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    console.log('Starting toast!?')
    const toast = new Toast(this.element)
    console.log(toast)
    toast.show();
  }

  dismiss() {
    this.element.remove();
  }
}
