import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forgot-password"
export default class extends Controller {
  static targets = ['email', 'submit']

  connect() {
  }

  validateEmail(){
    console.log('validating email')
    if(String(this.emailTarget.value).toLowerCase().match(/^\S+@\S+\.\S+$/)){
      this.submitTarget.classList.remove("disabled")
    } else {
      this.submitTarget.classList.add("disabled")
    }
  }
}
