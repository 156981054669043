import '@popperjs/core/dist/cjs/popper'
import { Dropdown, Modal } from 'bootstrap'
import "./controllers/index"
import "@hotwired/turbo-rails"

const dropdowns = document.querySelectorAll('.dropdown');
[].forEach.call(dropdowns, function (div) {
  new Dropdown(div);
});

function set_time_zone_offset() {
  document.cookie = 'time_zone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
}

set_time_zone_offset();
