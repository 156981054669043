import {Controller} from "@hotwired/stimulus"
import DataTables from 'datatables.net'

// Connects to data-controller="orders"
export default class extends Controller {
    static targets = ['sort', 'directionAsc', 'directionDesc']

    sortColumns(event) {
        const params = (new URL(document.location)).searchParams;

        const isAscending = params.get('search_orders_form[sort]') === event.target.value && this.directionAscTarget.checked

        if (isAscending) {
            this.directionDescTarget.click();
        } else {
            this.directionAscTarget.click();
        }

        event.target.closest("form").submit();
    }
}
