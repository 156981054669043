import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["actionButton"]

    connect() {
        this.actionButtonTarget.hidden = true

        this.element.addEventListener("change", () => {
            this.actionButtonTarget.hidden = false
        })
    }
}