import {Controller} from "@hotwired/stimulus"
import DataTables from 'datatables.net'
import Rails from "@rails/ujs";

// Connects to data-controller="products"
export default class extends Controller {
    static targets = [
        'selectItem',
        'selectAllItems',
        'selectedItemsCount',
        'directionAsc',
        'directionDesc',
        'manageChannelsSubmitButton',
        'actionsMenu',
    ]
    static values = {
        selectedItems: { type: Array, default: [] },
        selectedProviders: { type: Array, default: [] }
    }

    selectAllItems() {
        this.selectItemTargets.forEach((target) => target.checked = this.selectAllItemsTarget.checked)
        this.checkForSelectedItems()
    }

    selectItem() {
        this.checkForSelectedItems()
    }

    checkForSelectedItems() {
        const selectedItemsTargets = this.selectItemTargets.filter((target) => target.checked)
        const selectedItemsCount = selectedItemsTargets.length

        if (selectedItemsCount > 0) {
            this.selectedItemsValue = selectedItemsTargets.map(target => target.dataset.itemSirn)

            this.selectedItemsCountTargets.forEach(target => target.textContent = selectedItemsCount)

            this.actionsMenuTarget.classList.remove('invisible')

        } else {
            this.selectedItemsValue = []

            this.actionsMenuTarget.classList.add('invisible')
        }
    }

    selectProvider({ target: { checked, value }}) {
        if (checked) {
            this.addSelectedProvider(value)
        } else {
            this.removeSelectedProvider(value)
        }

        this.disableManageChannelsSubmitButton()
    }

    addSelectedProvider(value) {
        this.selectedProvidersValue = [...this.selectedProvidersValue, value]
    }

    removeSelectedProvider(value) {
        this.selectedProvidersValue = this.selectedProvidersValue.filter(
            (v) => v !== value
        )
    }

    disableManageChannelsSubmitButton() {
        this.manageChannelsSubmitButtonTarget.disabled = this.selectedProvidersValue.length === 0;
    }

    manageChannels() {
        this.manageChannelsSubmitButtonTarget.disabled = true

        const csrfToken = document.querySelector("meta[name=csrf-token]").content;
        const url = document.getElementById('manageChannelsModal').getAttribute('data-form-url');

        const data = {
            manage_channels: {
                item_sirns: this.selectedItemsValue,
                target_providers: this.selectedProvidersValue
            }
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    alert('Operation failed')
                    location.reload()
                    throw new Error('Something went wrong')
                }
            })
            .then(data => {
                const successMessage = 'Operation complete!'

                let failedItemsMessage = [];

                for (const provider in data.provider_results) {
                    const failedCount = data.provider_results[provider].failed_items.length

                    if (failedCount > 0) {
                        failedItemsMessage.push(`Failed to push ${failedCount} items to ${provider}`)
                    }
                }

                alert(successMessage + '\n' + (failedItemsMessage ? failedItemsMessage.join('\n') : ''))
                location.reload()
            })
    }

    sortColumns(event) {
        const params = (new URL(document.location)).searchParams;

        const isAscending = params.get('search_products_form[sort]') === event.target.value && this.directionAscTarget.checked

        if (isAscending) {
            this.directionDescTarget.click();
        } else {
            this.directionAscTarget.click();
        }

        event.target.closest("form").submit();
    }
}
