import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["view", "edit"]
    static values = {
        editable: Boolean
    }

    connect() {
        this.editableValue = true;
        this.toggle();
    }

    toggle() {
        this.editableValue = !this.editableValue;
        this.editTargets.map((target) => target.hidden = !this.editableValue);
        this.viewTargets.map((target) => target.hidden = this.editableValue);
    }
}
