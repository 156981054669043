import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clients"
export default class extends Controller {
  static targets = ['sort', 'directionAsc', 'directionDesc']

  sortColumns(event) {
    const params = (new URL(document.location)).searchParams;

    const isDescending = params.get('admin_search_clients_form[sort]') === event.target.value && this.directionDescTarget.checked

    if (isDescending) {
        this.directionAscTarget.click();
    } else {
        this.directionDescTarget.click();
    }

    event.target.closest("form").submit();
  }
}
