
import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";


// Connects to data-controller="products"
export default class extends Controller {
    static targets = ['selectAll', 'selectable', 'selectedCount', 'selectAllHeader', 'linkItemsBtn']
    // , 'sort', 'directionAsc', 'directionDesc']
    static classes = ["invisible"]

    connect() {
      this.checkItemSelected(0)
    }

    // sortColumns(event) {
    //     const params = (new URL(document.location)).searchParams;

    //     const isAscending = params.get('search_link_report_form[sort]') === event.target.value && this.directionAscTarget.checked

    //     if (isAscending) {
    //         this.directionDescTarget.click();
    //     } else {
    //         this.directionAscTarget.click();
    //     }

    //     event.target.closest("form").submit();
    // }

    selectAll() {
      let values = []
      let count = 0
        this.selectableTargets.forEach((target) => {
          if (this.selectAllTarget.checked && !target.checked) {
            count = count + 1
          } else {
            if (!this.selectAllTarget.checked && target.checked) {
              count = count - 1
            }
          }
          values.push(target.value)
          target.checked = this.selectAllTarget.checked;
        });
        this.update_item_link(values,this.selectAllTarget.checked)
        this.checkItemSelected(count)
    }

    select(event) {
      let count = 1
      this.update_item_link(event.target.value, event.target.checked)
      if ( !event.target.checked ) {
        count = -1
      }
      this.checkItemSelected(count)
    }

    update_item_link(values, checked) {
      const url = (new URL(document.location))
      Rails.ajax({
        url:        `${url}/update_item_link`,
        type:       'GET',
        data:       `ids=${values}&sync=${checked}`,
        dataType:   'script',
        success:    (_response, _status, xhr) => {
        },
        error:      (_response, _status, _xhr) => {},
        complete:   (_xhr, _status) => {
        }
      })
    }

    checkItemSelected(count){
      let totalCount = parseInt(this.selectedCountTarget.textContent) + count
      if (totalCount > 0) {
        this.linkItemsBtnTarget.classList.remove(this.invisibleClass)
      }else{
        this.linkItemsBtnTarget.classList.add(this.invisibleClass)
        this.selectAllTarget.checked = false
      }
      this.selectedCountTarget.textContent = totalCount
    }
}
