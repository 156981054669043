import {
  Controller
} from "@hotwired/stimulus"
// Connects to data-controller="turbo-widget"
export default class extends Controller {
  close() {
    const widget = this.element;
    const banner_type = this.element.getAttribute('id');
    fetch(`components/banners/dismiss/${banner_type}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(data => {
      widget.remove();
    });
  }
}
