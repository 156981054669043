import { Controller } from "@hotwired/stimulus"

const RADIO_TOGGLE = 'radio-selection-toggle'
// Connects to data-controller="radio-toggle"
export default class extends Controller {
  static targets = ["radioButton"]

  connect() {
    document.addEventListener(RADIO_TOGGLE, this.handleSelection.bind(this));
  }

  toggle(event) {
    console.log('Toggled radio');
    event.stopImmediatePropagation()

    const selectionEvent = new CustomEvent(RADIO_TOGGLE, {
      detail: {
        name: event.target.name,
        value: event.target.value
      }
    });
    document.dispatchEvent(selectionEvent);
    return false;
  }

  handleSelection(event) {
  }
}
