import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="product-link"
export default class extends Controller {
    static targets = ['view', 'edit', 'submit', 'toggle']
    static values = {
        editable: Boolean
    }

    connect() {
        this.editableValue = true;
        this.toggle();
    }

    submit() {
        this.editTargets.map((target) => target.disabled = target.tagName === "BUTTON");
    }

    validate() {
        const valid = this.toggleTargets.some((target) => target.checked)

        this.submitTarget.disabled = !valid
    }

    toggle() {
        this.editableValue = !this.editableValue;
        this.submitTarget.hidden = !this.editableValue;
        this.toggleTargets.map((target) => target.hidden = !this.editableValue);
        this.editTargets.map((target) => target.hidden = !this.editableValue);
        this.viewTargets.map((target) => target.hidden = this.editableValue);
    }

}
