import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["rangeSelect", "rangeInputs", "fromInput", "toInput"]

    connect() {
        this.showOrHideRangeInputs();
        this.rangeSelectTarget.addEventListener("change", (event) => {
            this.showOrHideRangeInputs()
            if (event.target.value !== "custom") {
                Rails.fire(event.target.form, "submit");
            }
        })
    }

    showOrHideRangeInputs() {
        if (this.rangeSelectTarget.value === "custom") {
            this.rangeInputsTarget.classList.remove("collapsed")
        } else {
            this.rangeInputsTarget.classList.add("collapsed")
        }
    }
}
