import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["enableBopis", "actionButtons", "syncOrders"]

    connect() {
        this.actionButtonsTarget.style.visibility = "hidden"
        this.element.addEventListener("change", () => {
            this.actionButtonsTarget.style.visibility = "visible"
        })

        for (const element of this.syncOrdersTargets) {
            this.getAssociatedBopisElement(element).disabled = !element.checked
        }
    }

    setSyncOrders(event) {
        if (!event.target.checked) {
            this.getAssociatedBopisElement(event.target).checked = false
        }

        this.getAssociatedBopisElement(event.target).disabled = !event.target.checked
    }

    getAssociatedBopisElement(element) {
        const providerIndex = element.attributes["data-provider-index"].value
        return this.enableBopisTargets[providerIndex]
    }
}