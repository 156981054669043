import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clear-and-reingest"
export default class extends Controller {
  static targets = [
    "submitButton",
    "integration",
    "form",
    "selectedIntegrationsContent",
  ];
  static values = {
    numSelectedIntegrations: { type: Number, default: 0 },
  };

  connect() {}

  onChangeSelectedIntegrations() {
    const selectedCheckboxes = this.integrationTargets.filter(
      (checkbox) => checkbox.checked
    );

    const selectedLabels = selectedCheckboxes.map(
      (checkbox) => checkbox.labels[0].textContent
    );

    this.selectedIntegrationsContentTarget.innerText = `• ${selectedLabels.join(
      "\n• "
    )}`;

    this.numSelectedIntegrationsValue = selectedCheckboxes.length;
  }

  onConfirm() {
    this.formTarget.submit();
  }

  numSelectedIntegrationsValueChanged() {
    this.submitButtonTarget.disabled = this.numSelectedIntegrationsValue <= 0;
  }
}
