import {Controller} from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["view", "edit", "checkbox", "text"]
    static values = {
        editable: Boolean
    }

    connect() {
        this.editableValue = true;
        this.toggle();
    }

    submit(e) {
        e.preventDefault();
        Rails.ajax({
            url: this.element.dataset['url'],
            type: "POST",
            data: `auto_push_new_products=${this.checkboxTarget.checked}`,
            success: () => {
                this.textTarget.innerHTML = this.checkboxTarget.checked ? "Enabled" : "Disabled";
                this.toggle();
            }
        });
    }

    toggle() {
        this.editableValue = !this.editableValue;
        this.editTargets.map((target) => target.hidden = !this.editableValue);
        this.viewTargets.map((target) => target.hidden = this.editableValue);
    }
}