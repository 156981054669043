import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="bulk-push"
export default class extends Controller {
    static targets = ["fromInput", "toInput", "filterSelect", "attributeInput", "searchButton", "pushButton"]

    connect() {
        this.searchButtonTarget.style.display = "none";
        this.fromInputTarget.addEventListener("change", this.makeListener(this.toInputTarget))
        this.toInputTarget.addEventListener("change", this.makeListener(this.fromInputTarget))
        this.filterSelectTarget.addEventListener("change", (event) => {
            this.chooseAttributeSection(event.target.value);
        });
        this.element.addEventListener("change", () => {
            this.searchButtonTarget.style.display = "block";
            this.pushButtonTarget.style.display = "none";
        })
        this.chooseAttributeSection(this.filterSelectTarget.value);
    }

    makeListener = (oppositeTarget) => (event) => {
        if (oppositeTarget.value === event.target.value) {
            oppositeTarget.value = ""
        }
    }

    chooseAttributeSection = (value) => {
        for (const input of this.attributeInputTargets) {
            input.disabled = input.dataset.attribute !== value;
        }
    }
}
